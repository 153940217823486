import Viewport from '@vaersaagod/tools/Viewport';
import $ from '@vaersaagod/tools/Dom';

export class ViewportTest {

    constructor(el) {

        this.el = el;
        this.breakpoint = $(this.el).find('[data-breakpoint]');
        this.info = $(this.el).find('[data-info]');

        Viewport.init();
        Viewport.on('breakpoint', this.onBreakpoint.bind(this));
        Viewport.on('resize', this.onUpdate.bind(this));
        Viewport.on('scroll', this.onUpdate.bind(this));

        this.onUpdate();

        $(this.el).find('[data-toggle-scrolling]').on('click', this.toggleScrolling.bind(this));
        $(this.el).find('[data-toggle-tabbing]').on('click', this.toggleTabbing.bind(this));
    }

    onBreakpoint(e) {
        this.breakpoint.html(`Old: ${e.detail.old.name}@${e.detail.old.size}<br>Current: ${e.detail.current.name}@${e.detail.current.size}`);
    }

    onUpdate() {
        const props = ['scrollingLocked', 'tabbingLocked', 'breakpoint', 'width', 'height', 'size', 'scroll', 'scrollTop', 'scrollLeft'];
        const markup = `<table>${props.map(prop => `<tr><td>Viewport.${prop}</td><td>${JSON.stringify(Viewport[prop])}</td></tr>`).join('')}<table>`;
        this.info.html(markup);
    }

    toggleScrolling(e) {
        e.preventDefault();
        console.log('toggleScrolling');
        if (Viewport.scrollingLocked) {
            Viewport.releaseScrolling();
        } else {
            Viewport.lockScrolling();
        }
        this.onUpdate();
    }

    toggleTabbing(e) {
        e.preventDefault();
        console.log('toggleTabbing!!!');
        if (Viewport.tabbingLocked) {
            Viewport.releaseTabbing();
        } else {
            Viewport.lockTabbing(this.el);
        }
        this.onUpdate();
    }

    destroy() {
        $(this.el).find('[data-toggle-scrolling]').off('click');
        $(this.el).find('[data-toggle-tabbing]').off('click');
    }
}

export default ViewportTest;
