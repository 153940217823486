import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';

export default el => {

    const $el = $(el);

    const onButtonClick = e => {
        e.preventDefault();
        // Do some AJAX
        request.get('/').then(res => {
            const {status, body, text} = res || {};
            console.log({status, body, text});
        }, error => {
            console.error(error);
        });
    };

    const init = () => {
        $el.on('click', 'button', onButtonClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };
};
