<template>
    <div class="demo__effect">
        <div class="demo__effect-toggle pl-20 pt-15 pb-15 pr-50" v-bind:class="{ '-disabled': disabled && !isActive }" v-on:click="!disabled || isActive ? isActive = !isActive : isActive = isActive">
            <span>{{ name }}</span>
            <span class="demo__effect-toggle-status" v-bind:class="{ '-active': isActive }"></span>
        </div>
        <div class="demo__effect-settings pt-20" v-if="config.settings !== undefined && config.settings[0].type !== 'none'" v-bind:class="{ '-active': isActive }">
            <div class="pb-20 pl-20 pr-20" v-for="(setting, index) in config.settings" :key="setting.handle">
                <div v-if="config.settings.length > 1" class="demo__effect-label">
                    {{ setting.name !== undefined ? setting.name : setting.handle }}
                </div>
                <div v-if="setting.type === 'slider'">
                    <vue-slider
                            v-model="values[index]"
                            v-on:change="onChange"
                            :min="setting.min"
                            :max="setting.max"
                            :interval="setting.interval">
                    </vue-slider>
                </div>
                <div v-if="setting.type === 'color'">
                    <color-picker v-model="values[index]">
                    </color-picker>
                </div>
                <div v-if="setting.type === 'dropdown'">
                    <select v-model="values[index]">
                        <option v-for="option in setting.options" v-bind:value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component';
    import { Compact } from 'vue-color';

    export default {
        name: "Effect",
        components: {
            'vue-slider': VueSlider,
            'color-picker': Compact
        },
        props: {
            config: Object,
            disabled: Boolean
        },
        computed: {
            name() {
                return (
                    this.config.name !== undefined ? this.config.name : this.config.handle.charAt(0).toUpperCase() + this.config.handle.slice(1)
                );
            }
        },
        watch: {
            values: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler() {
                    //console.log('values changed!', this.values);

                    this.values.forEach((val, i) => {
                        if (val['hex'] !== undefined) {
                            this.values[i] = val['hex'];
                            //console.log(val);
                        }
                    });

                    this.emitUpdated();
                }
            },
            isActive() {
                this.emitUpdated();
            },
            disabled() {
                console.log('disabled is ' + this.disabled);
            }
        },
        data() {
            return {
                isActive: false,
                values: [],
                color: '#ff0022'
            };
        },
        methods: {
            emitUpdated() {
                this.$emit('updated', { effect: this.config.handle, isActive: this.isActive, values: this.values });
            },
            onChange() {
                //console.log(this.values);
            }
        },
        created() {
            if (this.config.settings !== undefined) {
                this.config.settings.forEach((val, i) => {
                    //console.log(val, i);
                    this.values[i] = val.default;
                });
            }
        },
        mounted() {

        },
        destroyed() {

        }
    }
</script>

<style lang="scss">
    .demo__effect .vc-compact {
        width: 100%;
        padding-top: 0;
        padding-left: 0;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
    }

    .demo__effect .vc-compact-color-item {
        margin-right: 1px;
        margin-bottom: 1px;
        width: 19px;
        height: 19px;
    }
</style>
