import Vue from 'vue';
import Effect from '../vue/Effect';
import axios from "axios";
import { stringify } from 'qs'
import { Base64 } from 'js-base64';

import VueCompareImage from 'vue-compare-image';
import VueCustomScrollbar from 'vue-custom-scrollbar'

const axiosInstance = axios.create({
    headers: {
        'X-CSRF-Token': window.csrfToken
    },
    transformRequest: [
        function (data) {
            return stringify(data)
        },
    ],
});

Vue.prototype.$axios = axiosInstance;

/**
 * @param el
 * @param props
 * @returns {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {
        Effect,
        VueCompareImage,
        VueCustomScrollbar
    },
    data: {
        selectedImageId: props.selectedImage,
        imageData: null,
        endpointUrl: props.endpointUrl,
        currentTransform: { },
        reloadTimeout: -1,
        originalImage: '',
        transformedImage: '',
        limitReached: false,

        effectsDef: {
            all: [
                { handle: 'blur', name: 'Blur', settings: [{ handle: 'amount', type: 'slider', dataType: 'float', min: 0, max: 5, default: 1, interval: 0.1 }] },
                { handle: 'brightness', settings: [{ handle: 'amount', type: 'slider', dataType: 'int', min: -100, max: 100, default: 0, interval: 10 }] },
                { handle: 'colorize', settings: [{ handle: 'color', type: 'color', dataType: 'string', default: '#D33115' }] },
                { handle: 'gamma', settings: [{ handle: 'amount', type: 'slider', dataType: 'float', min: 0, max: 3, default: 1.25, interval: 0.25 }] },
                { handle: 'grayscale', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                { handle: 'negative', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                { handle: 'sharpen', name: 'Sharpen 🔥', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] }
            ],

            imagick: [
                {
                    handle: 'adaptiveBlur', name: 'Adaptive Blur', settings: [
                        { handle: 'radius', type: 'slider', dataType: 'int', min: 0, max: 10, default: 4, interval: 1 },
                        { handle: 'sigma', type: 'slider', dataType: 'int', min: 1, max: 10, default: 2, interval: 1 }
                    ]
                },
                {
                    handle: 'adaptiveSharpen', name: 'Adaptive Sharpen', settings: [
                        { handle: 'radius', type: 'slider', dataType: 'int', min: 0, max: 10, default: 3, interval: 1 },
                        { handle: 'sigma', type: 'slider', dataType: 'int', min: 1, max: 10, default: 2, interval: 1 }
                    ]
                },
                {
                    handle: 'clut', name: 'CLUT (color lookup table)️ 🔥', settings: [
                        { handle: 'from', type: 'color', dataType: 'string', default: '#194D33' },
                        { handle: 'fromOpacity', name: 'From opacity', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.5, interval: 0.1 },
                        { handle: 'to', type: 'color', dataType: 'string', default: '#A4DD00' },
                        { handle: 'toOpacity', name: 'To opacity', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.5, interval: 0.1 }
                    ]
                },
                { handle: 'colorBlend', name: 'Color Blend 🔥️', settings: [{ handle: 'color', type: 'color', dataType: 'string', default: '#D33115' }, { handle: 'opacity', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.5, interval: 0.1 }] },
                { handle: 'contrast', settings: [{ handle: 'amount', type: 'slider', dataType: 'int', min: -5, max: 5, default: 2, interval: 1 }] },
                {
                    handle: 'contrastStretch',
                    name: 'Contrast Stretch',
                    settings: [{ handle: 'from', type: 'slider', dataType: 'int', min: 20000, max: 200000, default: 30000, interval: 2000 }, { handle: 'to', type: 'slider', dataType: 'int', min: 20000, max: 200000, default: 180000, interval: 2000 }]
                },

                { handle: 'despeckle', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                { handle: 'enhance', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                { handle: 'equalize', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                {
                    handle: 'levels', settings: [
                        { handle: 'black', type: 'slider', dataType: 'int', min: -50, max: 255, default: 0, interval: 5 },
                        { handle: 'gamma', type: 'slider', dataType: 'float', min: 0, max: 2, default: 1, interval: 0.2 },
                        { handle: 'white', type: 'slider', dataType: 'int', min: 0, max: 300, default: 255, interval: 5 },
                        { handle: 'channel', type: 'dropdown', dataType: 'string', options: ['all', 'red', 'green', 'blue'], default: 'all' }
                    ]
                },
                {
                    handle: 'modulate', name: 'Modulate 🔥', settings: [
                        { handle: 'brightness', type: 'slider', dataType: 'int', min: 0, max: 200, default: 100, interval: 10 },
                        { handle: 'saturation', type: 'slider', dataType: 'int', min: 0, max: 200, default: 100, interval: 10 },
                        { handle: 'hue', type: 'slider', dataType: 'int', min: 0, max: 200, default: 100, interval: 10 }
                    ]
                },
                {
                    handle: 'motionBlur', name: 'Motion Blur', settings: [
                        { handle: 'radius', type: 'slider', dataType: 'int', min: 0, max: 20, default: 10, interval: 1 },
                        { handle: 'sigma', type: 'slider', dataType: 'int', min: 0, max: 100, default: 10, interval: 10 },
                        { handle: 'angle', type: 'slider', dataType: 'int', min: 0, max: 360, default: 0, interval: 45 }
                    ]
                },
                { handle: 'normalize', name: 'Normalize 🔥', settings: [{ handle: 'amount', type: 'none', dataType: 'bool', default: true }] },
                { handle: 'oilPaint', name: 'Oil Paint', settings: [{ handle: 'radius', type: 'slider', dataType: 'int', min: 0, max: 10, default: 5, interval: 1 }] },
                {
                    handle: 'opacity', settings: [
                        { handle: 'opacity', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.4, interval: 0.1 },
                        { handle: 'background', type: 'color', dataType: 'string', default: '#FCDC00' }
                    ]
                },
                {
                    handle: 'posterize', settings: [
                        { handle: 'colors', type: 'slider', dataType: 'int', min: 8, max: 32, default: 8, interval: 8 },
                        { handle: 'dithering', type: 'dropdown', dataType: 'string', default: 'no', options: ['no', 'riemersma', 'floydsteinberg'] }
                    ]
                },
                { handle: 'quantize', settings: [{ handle: 'colors', type: 'slider', dataType: 'int', min: 16, max: 256, default: 32, interval: 16 }] },
                { handle: 'radialBlur', name: 'Radial Blur', settings: [{ handle: 'amount', type: 'slider', dataType: 'int', min: 0, max: 10, default: 2, interval: 1 }] },
                { handle: 'sepia', settings: [{ handle: 'amount', type: 'slider', dataType: 'int', min: 0, max: 160, default: 80, interval: 40 }] },
                {
                    handle: 'tint', settings: [
                        { handle: 'color', type: 'color', dataType: 'string', default: '#0062B1' },
                        { handle: 'opacity', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.7, interval: 0.1 }
                    ]
                },
                {
                    handle: 'unsharpMask', name: 'Unsharp Mask 🔥', settings: [
                        { handle: 'radius', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0, interval: 0.1 },
                        { handle: 'sigma', type: 'slider', dataType: 'float', min: 0, max: 1, default: 0.5, interval: 0.1 },
                        { handle: 'amount', type: 'slider', dataType: 'float', min: 0, max: 1, default: 1, interval: 0.1 },
                        { handle: 'threshold', type: 'slider', dataType: 'float', min: 0, max: 0.2, default: 0.05, interval: 0.05 }
                    ]
                }
            ]
        }
    },
    computed: {
        imageUrl() {
            if (this.imageData && this.imageData.url !== 'undefined' && this.imageData.url !== '') {
                return this.imageData.url;
            }

            return null;
        },
        codeExample() {
            if (this.imageData === null || this.imageData.effectsString === '') {
                return '👈 Add some effects!'
            }
            
            return 'craft.imager.transformImage(image, { <span class="c-white">effects: ' + this.parseCode(this.imageData.effectsString) + '</span> })';
        }
    },
    watch: {
        selectedImageId() {
            this.reloadImage();
        },
        imageData: {
            deep: true,
            handler() {
                if (this.imageData !== null) {
                    this.$nextTick(function () {
                        this.updateImageView();
                    });
                }
            }
        }
    },
    methods: {
        changeImage(id) {
            this.selectedImageId = id;
        },
        reloadImage() {
            this.$axios.get(this.endpointUrl, {
                params: {
                    assetId: this.selectedImageId,
                    effects: Base64.encode(JSON.stringify(this.currentTransform), true)
                }
            })
                .then(({ data }) => {
                    //console.log(data);
                    if (data.success) {
                        //this.$refs.imageWrapImg.style.opacity = 0;
                        this.$refs.spinner.style.display = 'none';
                        this.imageData = data;
                    }
                })
                .catch(error => {
                    //console.error(error);
                });

        },
        updateImageView() {
            if (this.imageData) {
                const mainClientRect = this.$refs.mainArea.getBoundingClientRect();
                const mainAspectRatio = (mainClientRect.width - 80) / (mainClientRect.height - 220);
                const imageAspectRatio = this.imageData.width / this.imageData.height;

                let targetWidth, targetHeight;

                if (mainAspectRatio > imageAspectRatio) { // canvas is wider than image
                    targetHeight = Math.min(this.imageData.height, mainClientRect.height - 220);
                    targetWidth = targetHeight * imageAspectRatio;
                } else { // image is wider than canvas 
                    targetWidth = Math.min(this.imageData.width, mainClientRect.width - 80);
                    targetHeight = targetWidth * (this.imageData.height / this.imageData.width);
                }

                this.$refs.imageWrap.style.width = targetWidth + 'px';
                this.$refs.imageWrap.style.height = targetHeight + 'px';
                this.$refs.imageWrap.style.top = (mainClientRect.height - targetHeight) / 2 + 'px';
                this.$refs.imageWrap.style.left = (mainClientRect.width - targetWidth) / 2 + 'px';

                this.originalImage = this.imageData.original;
                this.transformedImage = this.imageData.transformed;

                /*
                const imageWrapBoundingRect = this.$refs.imageWrap.getBoundingClientRect();
                this.imageWrapRect = {
                    position: {
                        top: this.$refs.imageWrap.offsetTop,
                        left: this.$refs.imageWrap.offsetLeft
                    },
                    offset: {
                        top: imageWrapBoundingRect.top,
                        left: imageWrapBoundingRect.left
                    },
                    width: imageWrapBoundingRect.width,
                    height: imageWrapBoundingRect.height
                };
                
                this.adjustImagePosition();
                *
                 */
            }
        },

        parseCode(code) {
            return code;
        },
        
        onImageLoad() {
            //this.$refs.imageWrapImg.style.opacity = 1;
        },
        onResize() {
            this.updateImageView();
        },
        onEffectUpdated(value) {
            if (value.isActive) {
                this.currentTransform[value.effect] = value.values;
            } else {
                delete (this.currentTransform[value.effect]);
            }

            clearTimeout(this.reloadTimeout);
            this.$refs.spinner.style.display = 'block';

            this.reloadTimeout = setTimeout(() => {
                this.reloadImage();
            }, 500);
            
            this.limitReached = Object.keys(this.currentTransform).length >= 3;
        }
    },
    mounted() {
        this.reloadImage();
        window.addEventListener('resize', this.onResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize);
    }
});
