import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';

import * as eventKeys from '../../lib/events';

export default el => {

    const someEventHandler = (key, data) => {
        console.info('Gratulerer, det virker', { key, data });
    };

    Dispatch.on(eventKeys.SOME_EVENT, someEventHandler, true);

    el.innerHTML = '<button>Click me</button>';
    $(el).on('click', 'button', () => Dispatch.emit(eventKeys.SOME_EVENT, 'hei hei'));

    return {
        destroy() {
            $(el).off('click');
            Dispatch.off(eventKeys.SOME_EVENT, someEventHandler);
        }
    };
};
