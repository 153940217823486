/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const SOME_EVENT = 'SOME_EVENT';
export const SOME_OTHER_EVENT = 'SOME_OTHER_EVENT';
export const MENU_OPEN = 'MENU_OPEN';

